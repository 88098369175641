<template>
  <div class="ticket-transfer-record">
    <el-breadcrumb separator="|">
      <el-breadcrumb-item :to="{ name: 'tickets-management' }">票券管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'tickets-detail', query:{ticketId:ticketId}}">票券详情</el-breadcrumb-item>
      <el-breadcrumb-item>流转记录</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="transfer-info">
      <div class="detail">
        <div class="title">票券信息</div>
        <div class="body">
          <div class="ticket">
            <div class="ticket-header">
              <div class="name">
                <img src="../../../../assets/img/yimi.png" alt="">
                <span>{{infoDetail.sendUserName}}</span>
              </div>
              <div class="expiry">有效期至：{{infoDetail.ticketEndTime |  timeFilter('YYYY.MM.DD')}}</div>
              <!-- <div class="expiry">有效期至：{{row.exchangeTime | timeFilter('YYYY.MM.DD HH:mm:ss')}}</div> -->
            </div>
            <div class="ticket-content">
              <div class="name">{{infoDetail.ticketTypeName}}</div>
              <div>{{infoDetail.ticketName}}</div>
              <div class="code">兑换码：{{code}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="detail">
        <div class="title">流转记录</div>
        <div class="record">
          <!-- 单条流转记录 -->
          <div class="record-item" v-for="(item,index) in  list" :key="index">
            <div class="record-item-header">
              <div class="type-name">{{item.title}}</div>
              <div class="transfer-time">{{item.time | timeFilter('YYYY.MM.DD HH:mm:ss')}}</div>
            </div>
            <div class="record-item-content">
              <div class="owner">
                <div class="owner-info">
                  <img :src="item.icon?(item.icon | iconPath):require('../../../../assets/img/yimi.png')" alt="" class="avatar">
                  <span class="name">{{item.userName}}</span>
                </div>
                <div class="owner-phone" v-if="false">15521321123</div>
              </div>
              <div class="chain-hash">区块链记录标识：{{item.hash}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ticketEntityId: '',
      list: [],
      ticketId: '',
      infoDetail: {},
      code: ''
    }
  },
  filters: {
    iconPath (val) {
      return window.BASE_URL + val
    }
  },
  mounted() {
    this.ticketEntityId = this.$route.query.id
    this.ticketId = this.$route.query.ticketId
    this.code = this.$route.query.code

    this.infoDetail = JSON.parse(decodeURIComponent(this.$route.query.infoDetail))
    console.log(this.infoDetail)
    this.getRecord()
  },
  methods: {
    getRecord () {
      this.$http.ticket.ticketRecord({
        id: this.ticketEntityId
      }).then(res => {
        if (res.data.code === 0) {
          this.list = res.data.data
        }
      })
    }
  }
}
</script>

<style lang="scss">
.ticket-transfer-record {
  min-width: 440px;
  .el-breadcrumb {
    margin-bottom: 31px;
    &__item {
      line-height: 20px;
      .is-link {
        color: #b3b4ba;
        &:hover {
          color: $--color-primary;
        }
      }
      &[aria-current="page"] {
        font-size: 20px;
        .el-breadcrumb__inner {
          font-weight: 600;
          color: $--color-text-primary;
        }
      }
    }
    &__separator {
      font-size: 16px;
    }
  }
  .transfer-info {
    border: $--header-border;
    border-radius: $--border-radius-base;
    background-color: $--header-bg-color;
    overflow: hidden;
    background-color: $--header-bg-color;
    margin-bottom: 19px;
    padding: 50px 40px;
    .detail {
      .title {
        font-size: 24px;
        font-weight: 600;
        color: $--color-text-primary;
        padding-bottom: 20px;
        border-bottom: 1px solid #e5e8f3;
      }
      .body {
        padding-top: 40px;
        padding-left: 28px;
        padding-bottom: 58px;
        .ticket {
          position: relative;
          width: 480px;
          padding-left: 9px;
          padding-right: 9px;
          background: #f5f6fa;
          &-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px;
            border-bottom: 2px dashed #e1e2e6;
            .name {
              display: flex;
              align-items: center;
              font-size: 17px;
              color: $--color-text-primary;
              img {
                width: 27px;
                height: 27px;
                border-radius: 50%;
                margin-right: 8px;
              }
            }
            .expiry {
              font-size: 16px;
              color: #b3b4ba;
            }
          }
          &-content {
            padding-top: 30px;
            padding-left: 20px;
            padding-bottom: 24px;
            // padding-bottom: 24px;
            font-size: 16px;
            color: #b3b4ba;
            .name {
              font-size: 26px;
              font-weight: 600;
              margin-bottom: 13px;
              color: $--color-text-primary;
            }
            .code {
              margin-top: 22px;
            }
          }
          &::before {
            content: "";
            position: absolute;
            left: -7px;
            top: 60px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: $--header-bg-color;
          }
          &::after {
            content: "";
            position: absolute;
            right: -7px;
            top: 60px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
            background-color: $--header-bg-color;
          }
        }
      }
      .record {
        margin-top: 60px;
        margin-left: 104px;
        &-item {
          position: relative;
          width: 700px;
          height: 109px;
          &::before {
            content: "";
            position: absolute;
            width: 45px;
            height: 66px;
            left: -88px;
            top: 8px;
            background: url("../../../../assets/img/point.png");
          }
          &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .type-name {
              font-size: 20px;
              color: $--color-text-primary;
            }
            .transfer-time {
              font-size: 16px;
              color: #b3b4ba;
            }
          }
          &-content {
            font-size: 16px;
            margin-top: 16px;
            color: #b3b4ba;
            .owner {
              margin-bottom: 6px;
              display: flex;
              align-items: center;
              line-height: 20px;
              div + div {
                border-left: 1px solid #b3b4ba;
                padding-left: 16px;
                padding-right: 16px;
              }
              &-info {
                display: flex;
                align-items: center;
                .avatar {
                  width: 40px;
                  height: 40px;
                  border-radius: 50%;
                  border: 1px solid #b3b4ba;
                  overflow: hidden;
                  & + .name {
                    margin-left: 16px;
                    margin-right: 16px;
                  }
                }
                .name {
                  margin-right: 16px;
                }
              }
            }
          }
        }
        .record-item + .record-item {
          margin-top: 60px;
          &::after {
            content: "";
            position: absolute;
            height: 110px;
            top: -93px;
            left: -66px;
            border: none;
            border-left: 2px dashed #b3b4ba;
          }
        }
      }
    }
  }
}
.chain-hash{
  word-break: break-all;
}
</style>
